<template>
  <div>
    <h3>Create Large Area Audience</h3>
    <div class="bordered">
      <el-form class="form">
        <el-row :gutter="20">
          <el-col :md="12">
            <el-form-item label="Audience Name">
              <el-input v-model="form.name" />
            </el-form-item>
            <el-form-item label="Date Range">
              <date-picker
                :date-range.sync="form.dateRange"
                :organization="form.organization"
              />
            </el-form-item>
            <el-form-item label="Organization">
              <org-picker :organization.sync="form.organization" />
            </el-form-item>
            <el-form-item>
              <tag-picker :tags.sync="form.tags" />
            </el-form-item>
          </el-col>
          <el-col :md="12">
            <div class="csv-alert">
              <el-alert
                show-icon
                title="GeoJSON feature geometry other than polygons and multipolygons will be ignored."
                type="info"
              />
            </div>
            <uploader
              ref="uploader"
              :accept-file-exts="['.json', '.geojson']"
              single-file
              api-endpoint="#"
              upload-field-name="imports"
              @file-change="handleFileChange"
            >
              Drag GeoJSON file here
            </uploader>
          </el-col>
        </el-row>

        <el-form-item>
          <el-button
            type="primary"
            :loading="loading"
            :disabled="!formReady"
            @click="doImport"
          >
            Create Audience
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import area from '@turf/area';
import { mapGetters } from 'vuex';
import DatePicker from '../../global/DatePicker.vue';
import OrgPicker from '../../global/OrgPicker.vue';
import TagPicker from '../../global/TagPicker.vue';
import Uploader from '../../global/Uploader.vue';
import { dateToString } from '@/helpers';
import { audience } from '@/adonis-api';

export default {
  components: {
    DatePicker,
    OrgPicker,
    TagPicker,
    Uploader,
  },

  data() {
    return {
      busy: false,
      form: {
        name: '',
        dateRange: [],
        tags: [],
        organization: {},
        json: null,
      },
      files: [],
      loading: false,
      organizations: [],
    };
  },

  computed: {
    ...mapGetters('user', ['orgInfo']),

    formReady() {
      return (
        !this.loading &&
        this.form.name &&
        this.form.organization.id &&
        this.form.dateRange.length === 2 && this.form.dateRange.every(date => date !== null) &&
        this.form.json
      );
    },
  },

  methods: {
    async handleFileChange(fileList) {
      this.loading = true;

      const reader = new FileReader();
      reader.onload = (e) => {
        const body = reader.result;
        if (body.indexOf('Polygon') === -1) {
          this.$refs.uploader.reset();
          this.$notify.error({
            message: 'Invalid file detected. Please ensure your file is properly formatted geojson containing at least one polygon or multipolygon element.',
          });
          this.loading = false;
          return;
        }
        try {
          const json = Object.freeze(JSON.parse(body));
          const size = Math.abs(area(json));
          this.form.json = json;
        } catch (e) {
          this.$refs.uploader.reset();
          this.$notify.error({
            message: 'Invalid file detected. Please ensure your file is properly formatted geojson containing at least one polygon or multipolygon element.',
          });
        }
        this.loading = false;
      };

      reader.readAsText(fileList[0], 'UTF-8');
    },

    async doImport() {
      this.loading = true;
      const params = {
        name: this.form.name.trim(),
        type: 'LARGEAREA',
        organization_id: this.form.organization.id,
        tags: this.form.tags,
        json: this.form.json,
        start_date: dateToString(this.form.dateRange[0]),
        end_date: dateToString(this.form.dateRange[1]),
      };

      try {
        const response = await audience.createLargeAreaAudience(params);

        this.$notify.success({
          message: 'Your audience has been submit for processing.',
        });

        this.resetForm();
        this.$router.push('/audiences/library/list');
      } catch (e) {
        this.$reportError(e);
        this.$notify.error({
          message: e.error,
          duration: 7500,
        });
      } finally {
        this.resetForm();
        this.loading = false;
      }
    },

    resetForm() {
      this.$refs.uploader.reset();
      this.loading = false;
      this.form = {
        name: '',
        dateRange: [],
        tags: [],
        organization: this.$store.state.user.orgDetails,
        json: null,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
label {
  margin-bottom: 0.5rem;
  display: block;
  font-size: 14px;

  &.el-radio-button {
    display: inline-block !important;
  }

  i {
    font-size: 0.75rem;
  }
}

.bordered {
  margin: 1rem 0;
  padding: 15px 20px;
  border: 1px solid #eee;
  background: #fafafa;
}

.form-control {
  margin-bottom: 1rem;
  .el-input,
  .el-select {
    width: 100%;
  }
}

.help {
  font-size: 12px;
  color: #666;
}

.manual-instructions {
  font-size: 14px;
  line-height: 1.5;
}

.csv-alert {
  margin-bottom: 20px;
}
</style>
